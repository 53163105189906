import { WebLocale } from '@/enums/web-locale';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { useLanguageStore } from './composables/useStores';

const { language } = useLanguageStore();

type PromotionProviders = 119 | 337;

export const promotionProviderIcons: Record<PromotionProviders, string> = {
	119: `${JW_CONFIG.IMAGESCALER_URL}/icon/52449539/s100/amazonprime.webp`,
	337: `${JW_CONFIG.IMAGESCALER_URL}/icon/313118777/s100/disneyplus.webp`,
};

interface PromoText {
	/** Set `null` to disable. */
	ribbon: string | null;
	/** Set `null` to disable. */
	offerText: string | null;
	/** Set `null` to disable. */
	longText: string | null;
	/** Optional promotion start date in `YYYY-MM-DD` format. */
	startDate?: ISO8601;
	/** `YYYY-MM-DD` format. */
	endDate: ISO8601;
	/** The ID of the promoted provider. */
	provider: PromotionProviders;
	/** The Url of the promoted provider which overrides the regular offer link. */
	url?: string;
}

type PromotionCountries =
	| 'AR'
	| 'AU'
	| 'CL'
	| 'CO'
	| 'DK'
	| 'HK'
	| 'IE'
	| 'GR'
	| 'MX'
	| 'NZ'
	| 'PT'
	| 'RO'
	| 'SG'
	| 'TW'
	| 'US';

const promotions: Record<PromotionCountries, PromoText[]> = {
	AR: [
		{
			ribbon: language.value === 'es' ? 'OFERTA' : 'OFFER',
			offerText: language.value === 'es' ? 'PRUEBA GRATUITA' : 'FREE TRIAL',
			longText: language.value === 'es' ? '7 DÍAS GRATIS' : '7 DAY FREE TRIAL',
			startDate: '2024-08-29',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	AU: [
		{
			ribbon: 'OFFER',
			offerText: 'FREE TRIAL',
			longText: '30 DAY FREE TRIAL',
			startDate: '2024-08-30',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	CL: [
		{
			ribbon: language.value === 'es' ? 'OFERTA' : 'OFFER',
			offerText: language.value === 'es' ? 'PRUEBA GRATUITA' : 'FREE TRIAL',
			longText: language.value === 'es' ? '7 DÍAS GRATIS' : '7 DAY FREE TRIAL',
			startDate: '2024-08-29',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	CO: [
		{
			ribbon: language.value === 'es' ? 'OFERTA' : 'OFFER',
			offerText: language.value === 'es' ? 'PRUEBA GRATUITA' : 'FREE TRIAL',
			longText: language.value === 'es' ? '7 DÍAS GRATIS' : '7 DAY FREE TRIAL',
			startDate: '2024-08-29',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	DK: [
		{
			ribbon: '30-dages',
			offerText: 'gratis',
			longText: '30-dages gratis prøveversion',
			startDate: '2024-08-29',
			endDate: '2024-09-11',
			provider: 119,
			url: 'https://primevideo-eu.pxf.io/1rKvQm',
		},
	],
	HK: [
		{
			ribbon: 'OFFER',
			offerText: '快閃限時優惠',
			longText: '每日唔使 HK$2，精彩娛樂一整年！',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	IE: [
		{
			ribbon: '7 day',
			offerText: 'Free Trial',
			longText: 'Watch now 7 day free trial',
			startDate: '2024-08-29',
			endDate: '2024-09-11',
			provider: 119,
			url: 'https://primevideo-eu.pxf.io/1rKvQm',
		},
	],
	GR: [
		{
			ribbon: 'Δωρεάν',
			offerText: 'δοκιμή',
			longText: 'Δωρεάν δοκιμή 30 ημερών',
			startDate: '2024-08-29',
			endDate: '2024-09-11',
			provider: 119,
			url: 'https://primevideo-eu.pxf.io/1rKvQm',
		},
	],
	MX: [
		{
			ribbon: language.value === 'es' ? 'OFERTA' : 'OFFER',
			offerText: language.value === 'es' ? 'PRUEBA GRATUITA' : 'FREE TRIAL',
			longText: language.value === 'es' ? '30 DÍAS GRATIS' : '1 MONTH FREE TRIAL',
			startDate: '2024-08-29',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	NZ: [
		{
			ribbon: 'OFFER',
			offerText: 'FREE TRIAL',
			longText: '30 DAY FREE TRIAL',
			startDate: '2024-08-30',
			endDate: '2024-09-12',
			provider: 119,
			url: 'https://primevideo-row.pxf.io/c/1206980/1780063/20020',
		},
	],
	PT: [
		{
			ribbon: '30 dias',
			offerText: 'gratuito',
			longText: 'Teste gratuito de 30 dias',
			startDate: '2024-08-29',
			endDate: '2024-09-11',
			provider: 119,
			url: 'https://primevideo-eu.pxf.io/1rKvQm',
		},
	],
	RO: [
		{
			ribbon: '30 Zile',
			offerText: 'Gratuit',
			longText: 'Primele 30 Zile Gratuit',
			startDate: '2024-08-29',
			endDate: '2024-09-11',
			provider: 119,
			url: 'https://primevideo-eu.pxf.io/1rKvQm',
		},
	],
	SG: [
		{
			ribbon: 'OFFER',
			offerText: 'Save 25%*',
			longText: 'Less than S$10/month*',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	TW: [
		{
			ribbon: 'OFFER',
			offerText: '快閃限時優惠',
			longText: '每天不到 NT$5，精彩娛樂一整年！',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	US: [
		{
			ribbon: 'OFFER',
			offerText: '1.99/Month',
			longText: 'Get 3 Months For $1.99/Mo',
			startDate: '2024-09-18',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
};

export function getPromoText(country: string, offers: TitleDetail['offers'] = [], currentDate: Date = new Date()) {
	const texts = promotions[country as PromotionCountries];
	if (!texts || texts.length === 0 || offers.length === 0) return null;

	const promoText = texts.find(promo => offers.some(offer => offer.package.packageId === promo.provider));

	if (promoText == null) return null;
	// Checking if we're within the promo dates
	if (promoText.startDate && +currentDate < new Date(promoText.startDate).setUTCHours(0, 0, 0, 0)) return null;
	if (promoText.endDate && +currentDate > new Date(promoText.endDate).setUTCHours(23, 59, 59, 999)) return null;

	return promoText;
}

// Disney External Pixel Tracking
const oceaniaLocales = new Set([WebLocale.au, WebLocale.nz]);
const latamLocales = new Set([
	WebLocale.br,
	WebLocale.mx,
	WebLocale.ar,
	WebLocale.cl,
	WebLocale.co,
	WebLocale.pe,
	WebLocale.uy,
	WebLocale.ec,
	WebLocale.pa,
	WebLocale.cr,
	WebLocale.py,
	WebLocale.bo,
	WebLocale.gt,
	WebLocale.ni,
	WebLocale.do,
	WebLocale.sv,
	WebLocale.hn,
	WebLocale.ve,
]);

export function getDisneyImpressionUrl(packageId: number | null | undefined, webLocale: WebLocale) {
	if (packageId === 337 /* Disney+ */) {
		if (oceaniaLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1833790/9358';

		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1848147/9358';
	}

	if (packageId === 619 /* Star+ */) {
		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1584316/13591';
	}

	return null;
}

// TYPES
type digits = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type day = `${'0'}${Exclude<digits, '0'>}` | `${'1' | '2'}${digits}` | '30' | '31';
type month = `${'0'}${Exclude<digits, '0'>}` | '10' | '11' | '12';
type year = `20${digits}${digits}`;
type ISO8601 = `${year}-${month}-${day}`;
